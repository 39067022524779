import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SUBSCRIBERS from "../api/services/SUBSCRIBERS";
import Search from "../components/forms/Search";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import TableRowSubscriber from "../features/subscribers/components/TableRowSubscriber";
import Loader from "../components/ui/Loader";
import Layout from "../layouts/Layout";
import { ALL_SUBSCRIBERS } from "../mock";
import styles from "../styles/_screens/suppliers.module.scss";
import { isTokenExpired } from "../utils/isTokenExpired";
import { tr } from "date-fns/locale";
import Spinner from "../components/ui/Spinner";
import Modal from "../components/ui/Modal";

const Subscribers = () => {
  let formdata = new FormData();

  const navigate = useNavigate();

  const SUBSCRIBER_TABLE_HEAD = [
    // "#",
    "שם מנוי",
    "טלפון",
    "חיפושים",
    "מייל",
    "סטטוס",
    "סוג מנוי",
    "סוג לקוח",
    "נוצר בתאריך",
    "תאריך סיום חבילה",
    "השהייה",
    "",
  ];

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(50);

  const [totalRecords, setTotalRecords] = useState(0);

  const [totalSubscribers, setTotalSubscribers] = useState(0);

  const [subscribers, setSubscribers] = useState();

  const [originalSubscribers, setOriginalSubscribers] = useState();

  const [isUpdated, setIsUpdated] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [deleteSuccess, setDeleteSuccess] = useState(null);

  const [filterValue, setFilterValue] = useState("");

  const [isSubDltModalOpen, setIsSubDltModalOpen] = useState(false);

  const [dltSubLoading, setDltSubLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // fetch subscribers list
  useEffect(() => {
    setDeleteSuccess(null);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching with filter:", filterValue);
        let res;
        if (filterValue === "businessSubscriber") {
          // For business, fetch all records at once
          res = await SUBSCRIBERS.get(1, 10000); // Use a large number to get all records
          if (res.result && res.result.records) {
            res.result.records = res.result.records.filter(
              (sub) => sub.subscriberType !== "PRIVATE"
            );
            // Update total count for business records
            res.result.totalSubscribers = res.result.records.length;
          }
        } else {
          // For private or no filter, use API with pagination
          res = await SUBSCRIBERS.get(page, limit, filterValue);
        }
        console.log("API Response:", res);

        if (!res.result) {
          setOriginalSubscribers([]);
          setSubscribers([]);
          setTotalRecords(0);
          setTotalSubscribers(0);
          return;
        }

        setTotalRecords(res.result.totalSubscribers);
        setTotalSubscribers(res.result.totalSubscribers);

        if (!res.result.records || res.result.records.length === 0) {
          console.log("No records found");
          setOriginalSubscribers([]);
          setSubscribers([]);
        } else {
          console.log("Sorting and setting records");
          let sortedItems = res.result.records.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          console.log("Sorted items:", sortedItems.length);
          setOriginalSubscribers(sortedItems);
          setSubscribers(sortedItems);
        }
      } catch (error) {
        console.log("API Error:", error);
        console.log("Error response:", error.response);
        toast.error(error.response?.data?.message || "An error occurred");
        if (error.response?.data) {
          isTokenExpired(
            error.response.data.status,
            error.response.data.message,
            navigate
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isUpdated, deleteSuccess, limit, filterValue, page, navigate]);

  // search
  const searchSubmit = async (e) => {
    e.preventDefault();

    if (searchValue.trim()) {
      try {
        const res = await SUBSCRIBERS.search(searchValue);
        if (
          !res.result ||
          !res.result.records ||
          res.result.records.length === 0
        ) {
          setSubscribers([]);
        } else {
          setSubscribers(res.result.records);
        }
        setSearchValue("");
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.message || "An error occurred");
        if (error.response?.data) {
          isTokenExpired(
            error.response.data.status,
            error.response.data.message,
            navigate
          );
        }
      }
    }
  };

  const handleSubscriberTypeFilter = (value) => {
    console.log("Setting filter value to:", value);
    setSubscribers([]);
    setOriginalSubscribers([]);
    setFilterValue(value);
    setPage(1);
    setLimit(50);
  };

  // delete subscriber
  const deleteSubscriber = async (id) => {
    formdata.append("id", id);
    formdata.append("isDeleted", true);

    setDltSubLoading(true);
    try {
      const res = await SUBSCRIBERS.update(formdata);
      toast.success(res.message || "Subscriber deleted successfully");
      setDeleteSuccess(true);
      setIsSubDltModalOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "An error occurred");
    }
    setDltSubLoading(false);
  };

  if (!subscribers) {
    return (
      <Layout>
        <div className={styles.container}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        {/* SEARCH */}
        <Search
          placeholder="חיפוש מנוי"
          mb={2}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          handleSubmit={searchSubmit}
        />
        {/* TABLE */}
        <Table
          mb={2}
          title={`רשימת מנויים (${totalSubscribers.toLocaleString()})`}
          exportBtnText="ייצוא"
          showSubscriberTypeFilter={true}
          onFilterChange={handleSubscriberTypeFilter}
          isLoading={isLoading}
          filterValue={filterValue}
        >
          <TableHead data={SUBSCRIBER_TABLE_HEAD} />
          <tbody>
            {subscribers && subscribers.length > 0 ? (
              subscribers.map((row) => (
                <TableRowSubscriber
                  key={row._id}
                  row={row}
                  setIsUpdated={setIsUpdated}
                  isUpdated={isUpdated}
                  setIsSubDltModalOpen={setIsSubDltModalOpen}
                />
              ))
            ) : (
              <tr className={styles.emptyRow}>
                <td colSpan={7}>לא נמצאו רשומות</td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* Only show load more for non-business filter */}
        {!filterValue.includes("BUSINESS") && limit < totalRecords && (
          <button
            className={styles.loadMore}
            onClick={() => setLimit((prev) => Number(prev) + 50)}
          >
            טען עוד
          </button>
        )}
        {/* DELETE SUBSCRIBER MODAL */}
        {isSubDltModalOpen && (
          <Modal setIsOpen={setIsSubDltModalOpen}>
            <div className={styles.modalContainer}>
              <h1>האם אתה בטוח?</h1>
              <p>אתה בטוח שאתה רוצה למחוק את המשתמש?</p>

              <div className={styles.btnContainer}>
                <button onClick={() => setIsSubDltModalOpen(false)}>לא</button>
                <button onClick={() => deleteSubscriber(isSubDltModalOpen)}>
                  {dltSubLoading ? <Spinner /> : "כן"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Subscribers;
