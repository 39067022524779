import { del, get, post, put } from "../client";
import {
  CREATE_SUBSCRIBER_COMMENT,
  DELETE_SUBSCRIPTION,
  PAYMENT_HISTORY,
  SEARCH_SUBSCRIBER,
  SUBSCRIBERS,
  SUBSCRIBER_BY_ID,
  SUBSCRIBER_COMMENTS,
  SUBSCRIBE_USER,
  UPDATE_SUBSCRIBER,
} from "../routes";

export default {
  get: async (page, size, type) => await get(SUBSCRIBERS(page, size, type)),

  get_by_id: async (id) => await get(SUBSCRIBER_BY_ID(id)),

  update: async (data) => await put(UPDATE_SUBSCRIBER, data),

  search: async (name) => await get(SEARCH_SUBSCRIBER(name)),

  get_comments: async (id) => await get(SUBSCRIBER_COMMENTS(id)),

  create_comment: async (data) => await post(CREATE_SUBSCRIBER_COMMENT, data),

  payment_history: async (id) => await get(PAYMENT_HISTORY(id)),

  subscribe: async (data) => await post(SUBSCRIBE_USER, data),

  cancel_subscription: async (id) => await del(DELETE_SUBSCRIPTION(id)),
};
