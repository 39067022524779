import { useNavigate } from "react-router-dom";
import Card from "../cards/Card";
import { BASE_URL } from "../../config";
import styles from "../../styles/_components/table.module.scss";

const Table = ({
  children,
  title,
  buttonText,
  exportBtnText,
  more,
  morePath,
  mb,
  setIsModalOpen,
  onFilterChange,
  showSubscriberTypeFilter,
  isLoading,
  filterValue,
}) => {
  const navigate = useNavigate();

  const handleFilterChange = (value) => {
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  return (
    <Card mb={mb}>
      <div className={styles.main}>
        {/* HEAD */}
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <h2>{title}</h2>
            {showSubscriberTypeFilter && (
              <div className={styles.filterContainer}>
                <button
                  className={`${styles.filterButton} ${
                    filterValue === "privateSubscriber" ? styles.active : ""
                  } ${isLoading ? styles.loading : ""}`}
                  onClick={() =>
                    handleFilterChange(
                      filterValue === "privateSubscriber"
                        ? ""
                        : "privateSubscriber"
                    )
                  }
                  disabled={isLoading}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  פרטי
                </button>
                <button
                  className={`${styles.filterButton} ${
                    filterValue === "businessSubscriber" ? styles.active : ""
                  } ${isLoading ? styles.loading : ""}`}
                  onClick={() =>
                    handleFilterChange(
                      filterValue === "businessSubscriber"
                        ? ""
                        : "businessSubscriber"
                    )
                  }
                  disabled={isLoading}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 21H21"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 21V17C9 16.4696 9.21071 15.9609 9.58579 15.5858C9.96086 15.2107 10.4696 15 11 15H13C13.5304 15 14.0391 15.2107 14.4142 15.5858C14.7893 15.9609 15 16.4696 15 17V21"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 9H16"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 13H16"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  עסקי
                </button>
                {isLoading && <div className={styles.loadingSpinner} />}
              </div>
            )}
          </div>

          <div className={styles.btnContainer}>
            {exportBtnText && (
              <a
                href={`${BASE_URL}/admin/export?type=${
                  title.startsWith("רשימת ספקים") ? "suppliers" : "subscribers"
                }`}
              >
                <button>{exportBtnText}</button>
              </a>
            )}
            {buttonText && (
              <button onClick={() => setIsModalOpen(true)}>
                <img src="/assets/icons/plus.svg" alt="add" />
                {buttonText}
              </button>
            )}
          </div>
        </div>

        {/* TABLE */}
        <table>{children}</table>

        {/* MORE */}
        {more && (
          <p className={styles.more} onClick={() => navigate(morePath)}>
            {more}
          </p>
        )}
      </div>
    </Card>
  );
};

export default Table;
